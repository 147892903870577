<template>
    <Detail />
</template>

<script>
import Detail from "../../components/Index/Researcher/ResearcherDetail/Detail.vue";

export default {
    name: "ResearcherDetailPage",
    components: {
        Detail,
    },
};
</script>

<style></style>
