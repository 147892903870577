const opendataRoute = [
    {
        path: "/openapi",
        name: "index",
        // route level code-splitting
        // this generates a separate chunk (Api.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Api" */ "./index.vue"),
    },
    {
        path: "/termservice",
        name: "TermService",
        // route level code-splitting
        // this generates a separate chunk (loginApi.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "loginApi" */ "./termservice.vue"),
    },
    {
        path: "/requestform",
        name: "RequestForm",
        // route level code-splitting
        // this generates a separate chunk (RegisterApi.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "RegisterApi" */ "./requestform.vue"),
    },
    {
        path: "/services",
        name: "Services",
        // route level code-splitting
        // this generates a separate chunk (DataApi.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "DataApi" */ "./services.vue"),
    },
];

export default opendataRoute;
