<template>
    <div>
        <KnowhowCbt />
    </div>
</template>

<script>
import KnowhowCbt from "../../components/Learning/KnowhowCbt.vue";

export default {
    name: "CommunityLearningIndex",
    components: {
        KnowhowCbt,
    },
};
</script>

<style></style>
