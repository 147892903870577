const tourismExperienceRoute = [
    {
        path: "/tourism-experience",
        name: "TourismExperienceIndex",
        // route level code-splitting
        // this generates a separate chunk (community.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("./TourismExperienceIndex.vue"),
    },
];
export default tourismExperienceRoute;
