<template>
    <!-- <VdeBanner />
    <ChooseTrip /> -->
    <GridMenu />
    <!-- <TripsYourMake />
    <RecommenTrip />
    <PopularLocation /> -->
</template>

<script>
import GridMenu from "../../components/PlanTrip/GridMenu.vue";

export default {
    name: "PlanTrip",
    components: {
        GridMenu,
    },
};
</script>
