<template>
    <div class="popup">
        <div class="dis-flex">
            <button @click="TogglePopup()">close</button>
        </div>
        <div class="popup-inner">
            <ShareNetwork
                network="facebook"
                :url="urlcode"
                title="เส้นทางท่องเที่ยว"
            >
                <img
                    style="border-radius: 100%"
                    src="images/icon/facebook.png"
                    alt=""
                />
            </ShareNetwork>
            <img src="images/icon/instagram.png" alt="" />
            <ShareNetwork network="line" :url="urlcode">
                <img src="images/icon/line.png" alt=""
            /></ShareNetwork>
        </div>
        <ShareNetwork network="facebook" :url="urlcode">
            <button class="btn-chare">
                แชร์ไปหน้าโปรไฟล์ Trinity
            </button></ShareNetwork
        >
    </div>
</template>

<script>
export default {
    props: ["TogglePopup"],
    data() {
        return {
            idmap: 0,
            urlcode: "https://trinitytrip.com/app/showTrip?id=",
            facebookUrl: "https://www.facebook.com/trinitytrip2022",
        };
    },
    mounted() {
        this.idmap = this.$route.query.id;
        this.urlcode += this.$route.query.id;
    },
};
</script>

<style>
.popup {
    position: absolute;
    margin-top: 10%;
    margin-left: 25%;
    z-index: 700;
    border-radius: 20px;
    padding: 0px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 1px 3px;
}
.popup .popup-inner {
    display: flex;
    padding: 0px 50px;
}
.popup .popup-inner img {
    width: 30px;
    height: auto;
    margin: 20px;
}
.btn-chare {
    text-align: center;
    padding: 5px 50px;
    background: #4976c2;
    border-radius: 10px;
    color: white;
    box-shadow: 0px 1px 3px #777;
    margin-bottom: 10px;
}
.dis-flex {
    display: flex;
    justify-content: flex-end;
    padding: 0px 10px;
}
</style>
