<template>
    <router-view />
    <notifications group="request" position="top right" class="noti" />
    <back-to-top bottom="50px" right="50px">
        <button type="button" class="btn btn-info btn-to-top">
            <svg
                class="svg-icon"
                style="
                    width: 1em;
                    height: 1em;
                    vertical-align: middle;
                    fill: currentColor;
                    overflow: hidden;
                "
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M141.1 746.8c15.2 12.7 38 10.7 50.7-4.4L512 360.8l320.2 381.6c12.7 15.2 35.6 17.2 50.7 4.4 15.2-12.7 17.2-35.6 4.4-50.7L539.6 281.6l-0.3-0.3-0.6-0.6c-0.2-0.2-0.4-0.5-0.7-0.7l-0.6-0.6-0.7-0.7c-0.2-0.2-0.4-0.4-0.6-0.5-0.3-0.2-0.5-0.5-0.8-0.7-0.1-0.1-0.2-0.2-0.3-0.2-0.1-0.1-0.2-0.2-0.4-0.3-0.2-0.2-0.5-0.4-0.7-0.6-0.3-0.2-0.5-0.4-0.8-0.6-0.2-0.1-0.4-0.3-0.6-0.4-0.3-0.2-0.7-0.5-1-0.7-0.1-0.1-0.3-0.2-0.4-0.2-0.4-0.3-0.8-0.5-1.3-0.8l-0.1-0.1c-4.6-2.6-9.6-4.1-14.7-4.6h-0.2c-0.5 0-0.9-0.1-1.4-0.1h-3.2c-0.5 0-0.9 0.1-1.4 0.1h-0.2c-5.1 0.4-10.1 2-14.7 4.6l-0.1 0.1c-0.4 0.2-0.8 0.5-1.3 0.8-0.1 0.1-0.3 0.2-0.4 0.2-0.3 0.2-0.7 0.4-1 0.7-0.2 0.1-0.4 0.3-0.6 0.4-0.3 0.2-0.5 0.4-0.8 0.6-0.2 0.2-0.5 0.4-0.7 0.6-0.1 0.1-0.2 0.2-0.4 0.3-0.1 0.1-0.2 0.2-0.3 0.2-0.3 0.2-0.5 0.4-0.8 0.7-0.2 0.2-0.4 0.4-0.6 0.5l-0.7 0.7-0.6 0.6c-0.2 0.2-0.4 0.5-0.7 0.7l-0.6 0.6-0.3 0.3-347.3 414.5c-12.8 15.1-10.8 38 4.4 50.7z"
                />
            </svg>
        </button>
    </back-to-top>
</template>
<style>
* {
    font-family: "Prompt", sans-serif !important;
    letter-spacing: 0 !important;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.fontstyle {
    font-family: "Prompt", sans-serif;
}
#page-detail {
    font-family: "Prompt", sans-serif;
}
.letter-spacing {
    letter-spacing: 0;
}
#img-blog {
    width: auto;
    max-width: 100%;
    height: 500px;
    overflow: hidden;
    display: block;
    margin: auto;
}
#img-select {
    width: auto;
    height: 150px;
    overflow: hidden;
    display: block;
    margin: auto;
}
.vel-btns-wrapper .btn__next {
    right: 12px;
    border-radius: 20px;
    background-color: rgb(0, 162, 231);
}
.vel-btns-wrapper .btn__prev {
    left: 12px;
    border-radius: 20px;
    background-color: rgb(0, 162, 231);
}

.vueperslides--fixed-height.vueperslides--bullets-outside {
    margin-bottom: 4em;
    margin-top: 0.5rem;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #b1afaf;
}

.noti {
    margin: 50px;
    margin-top: 100px;
}
</style>
